<template>
  <fw-layout back-to="-1" management mobile-ready>
    <template v-if="year" #main-sidebar>
      <SidebarManageVacationsYear />
    </template>

    <template #main-content>
      <fw-panel :title="'Configurações'" featured class="mb-5"></fw-panel>

      <fw-panel
        v-if="!loading && year && config"
        :title="'Cumulação de dias'"
        :loading="saving"
        after-loading-checked
        boxed="sm"
        custom-class="flex flex-col gap-5"
      >
        <div class="flex gap-5">
          <div class="flex flex-col gap-2">
            <fw-label marginless>Data a disponibilizar</fw-label>
            <div class="flex-1">
              <b-datepicker
                v-model="config.data.date"
                :disabled="!canEdit"
                locale="pt-PT"
                :min-date="minDate"
                :max-date="maxDate"
                icon="calendar-alt"
                placeholder="Data de início"
                trap-focus
              >
              </b-datepicker>
              <fw-tip v-if="$v.config.data.date.$error" error>
                <span v-if="!$v.config.data.date.required">Insira a data de início</span>
                <span v-else>Data de início inválida</span>
              </fw-tip>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <fw-label marginless>Estado</fw-label>
            <div class="flex-1 flex flex-col place-content-center">
              <b-checkbox v-model="config.is_active" :disabled="!canEdit">
                Ativo
              </b-checkbox>
            </div>
          </div>
        </div>
        <div>
          <fw-button type="primary" :loading="saving" :disabled="saving || loading" @click.native="save">
            Guardar
          </fw-button>
        </div>
      </fw-panel>

      <fw-panel-info label="Data (raw)" class="mt-4">
        <json-viewer :value="{ config, $v }"></json-viewer>
      </fw-panel-info>
    </template>
  </fw-layout>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import SidebarManageVacationsYear from '@/components/sidebars/manage/SidebarManageVacationsYear.vue'
export default {
  components: {
    SidebarManageVacationsYear
  },

  data() {
    return {
      config: null,
      loading: false,
      saving: false
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    minDate() {
      return new Date(this.year, 0, 1)
    },
    maxDate() {
      return new Date(this.year, 11, 31)
    },
    year() {
      return this.$route.params.year
    },
    canEdit() {
      return this.year == new Date().getFullYear()
    }
  },

  validations() {
    return {
      config: {
        data: {
          date: { min: minValue(this.minDate), max: maxValue(this.maxDate) }
        },
        is_active: { required }
      }
    }
  },

  mounted() {
    if (this.year) this.getCumulateDaysConfig()
  },

  methods: {
    async getCumulateDaysConfig() {
      console.log('getCumulateDaysConfig')
      this.loading = true
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getManagerConfigs(`accumulate_days_${this.year}`)
        console.log('getManagerConfigs :>> ', response)
        if (response.data.date) response.data.date = new Date(response.data.date)
        this.config = response
      })
      this.loading = false
    },

    async save() {
      console.log('getCumulateDaysConfig')
      this.saving = true
      await utils.tryAndCatch(this, async () => {
        const payload = JSON.parse(JSON.stringify(this.config))
        if (payload.data.date) payload.data.date = Dates.formatDateToAPI(payload.data.date)
        const response = await this.api.updateManagerConfigs(`accumulate_days_${this.year}`, payload)
        console.log('updateManagerConfigs :>> ', response)
      })
      this.saving = false
    }
  }
}
</script>
